import React, { Fragment, useEffect } from "react";
import Header from "../../components/Header";
import Blog from "./Blog";
import Footer from "../../components/Footer";

const Index = () => {
    useEffect(() => {
        window.scroll(0,0)
    },[])
  return (
    <Fragment>
      <div id="site-wrapper" className="site-wrapper home-main">
        <Header />
        <div className="content-wrap">
          <Blog />
        </div>
        <Footer/>
      </div>
    </Fragment>
  );
};

export default Index;
