import "./App.css";
import Home from "./screens/Home/Index";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import GetInTouch from "./screens/GetInTouch/Index";
import ContactUs from "./screens/ContactUs/Index";
import Blog from "./screens/Blogs/Index";

function App() {
   

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/get-in-touch/:form_type",
      element: <GetInTouch />,
    },
    {
      path: "/contactUs",
      element: <ContactUs />,
    },
    {
      path: "/blog",
      element: <Blog />,
    },
  ]);
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
