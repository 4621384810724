import React, { useEffect, useState } from "react";
import { colors } from "../utils/constants";
import Button from "./Button";

const GetInTouchForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showValidError, setShowValidError] = useState(false);

  const [messageData, setMessageData] = useState({
    message: "",
    name: "",
    email: "",
  });

  useEffect(() => {
    if (showError) {
      setTimeout(() => {
        setShowError(false);
      }, 2000);
    }
  }, [showError]);

  useEffect(() => {
    if (showSuccess) {
      setTimeout(() => {
        setShowSuccess(false);
      }, 2000);
    }
  }, [showSuccess]);

  useEffect(() => {
    if (showValidError) {
      setTimeout(() => {
        setShowValidError(false);
      }, 2000);
    }
  }, [showValidError]);

  const handleEmailClick = () => {
    if (messageData.name !== "" && messageData.email !== "") {
      sendEmail();
    } else {
      setShowValidError(true);
    }
    // if (messageData.name !== "" && messageData.email !== "") {
    //   var mailtoLink =
    //     "mailto:" +
    //     adminMailId +
    //     "?subject=" +
    //     encodeURIComponent(
    //       `Mail from Viionn website from ${messageData.name}`
    //     ) +
    //     "&body=" +
    //     encodeURIComponent(
    //       "Sender's Name: " +
    //         messageData.name +
    //         "\n\n" +
    //         "Sender's Email: " +
    //         messageData.email +
    //         "\n\n" +
    //         "Message: " +
    //         messageData.message
    //     );

    //   window.location.href = mailtoLink;
    //   setMessageData({
    //     message: "",
    //     name: "",
    //     email: "",
    //   });
    // } else {
    //   setShowError(true);
    // }
  };

  // const apiUrl = "http://localhost:8000/send-email";
  const apiUrl =
    "https://viionn-server.netlify.app/.netlify/functions/api/send-email";
  // const apiUrl = "http://192.168.1.149:9999/.netlify/functions/api/send-email";

  const sendEmail = async () => {
    setIsLoading(true);
    const emailData = {
      to: messageData.email,
      subject: messageData.name,
      text: messageData.message,
    };

    await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(emailData),
      mode: "cors",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("HTTP status " + response.status);
        }
        return response.text();
      })
      .then((data) => {
        console.log(data);
        setShowSuccess(true);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        setShowError(true);
        setIsLoading(false);
      });
  };

  return (
    <div className="git-right-container box-shadow css-margin-man-cu">
      <div>GET IN TOUCH</div>
      <div
        style={{
          borderBottom: `5px solid ${colors.mainColor}`,
          width: "50px",
          margin: "auto",
          marginTop: "10px",
        }}
      ></div>
      <div className="row">
        <div className="col-md-6 mt-4">
          <input
            className="form-control"
            placeholder="Your Full Name (Required)"
            onChange={(e) =>
              setMessageData({ ...messageData, name: e.target.value })
            }
          />
        </div>
        <div className="col-md-6 mt-4">
          <input
            className="form-control"
            placeholder="Your Email (Required)"
            onChange={(e) =>
              setMessageData({ ...messageData, email: e.target.value })
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mt-4">
          <textarea
            rows={5}
            className="form-control w-100"
            placeholder="Enter message (Optional)"
            onChange={(e) =>
              setMessageData({ ...messageData, message: e.target.value })
            }
          />
        </div>
      </div>
      {showValidError && (
        <div
          className="alert alert-danger mt-3"
          style={{ marginBottom: "-30px" }}
          role="alert"
        >
          Enter required fields!
        </div>
      )}
      {showError && (
        <div
          className="alert alert-danger mt-3"
          style={{ marginBottom: "-30px" }}
          role="alert"
        >
          Internal Server Error! Please try after some time.
        </div>
      )}
      {showSuccess && (
        <div
          className="alert alert-success mt-3"
          style={{ marginBottom: "-30px" }}
          role="alert"
        >
          Email sent successfully!
        </div>
      )}
      <div className="mt-5">
        <Button
          label={isLoading ? "Sending Request..." : "TALK TO A FRIEND"}
          width="fit-content"
          background={colors.mainColor}
          color={"white"}
          onClick={handleEmailClick}
        />
      </div>
    </div>
  );
};

export default GetInTouchForm;
