import React from "react";
import Zoom from "react-reveal/Zoom";
import reactIcon from "../../assets/images/languages/react_border.png";
import flutterIcon from "../../assets/images/languages/flutter.png";
import angularIcon from "../../assets/images/languages/angular.png";
import reduxIcon from "../../assets/images/languages/redux.png";
import typescriptIcon from "../../assets/images/languages/typescript.png";
import bootstrapIcon from "../../assets/images/languages/bootstrap.png";
import htmlIcon from "../../assets/images/languages/html.png";
import cssIcon from "../../assets/images/languages/css.png";
import nodeIcon from "../../assets/images/languages/node.png";
import expressIcon from "../../assets/images/languages/express.png";
import nextIcon from "../../assets/images/languages/next.png";
import socketIcon from "../../assets/images/languages/socket.png";
import laravelIcon from "../../assets/images/languages/laravel.png";
import mongoIcon from "../../assets/images/languages/mongo.png";
import postgressIcon from "../../assets/images/languages/postgress.png";
import mysqlIcon from "../../assets/images/languages/mysql.png";
import mariadbIcon from "../../assets/images/languages/mariadb.png";
import awsIcon from "../../assets/images/languages/aws.png";
import cloudIcon from "../../assets/images/languages/cloud.png";
import azureIcon from "../../assets/images/languages/azure.png";
import jenkinsIcon from "../../assets/images/languages/jenkins.png";
import dockerIcon from "../../assets/images/languages/docker.png";
import ansibleIcon from "../../assets/images/languages/ansible.png";
import KubernetesIcon from "../../assets/images/languages/Kubernetes.png";
import { Flip } from "react-reveal";
import { colors } from "../../utils/constants";

const FocusedOnBeing = ({framworks_Ref}) => {
  const FirstRowfocusOn = [
    {
      type: "MOBILE APP",
      language: [
        { name: "React Native", icon: reactIcon },
        { name: "Flutter", icon: flutterIcon },
      ],
    },
    {
      type: "WEB FRONTEND",
      language: [
        { name: "React JS", icon: reactIcon },
        { name: "Anguler", icon: angularIcon },
        { name: "Redux", icon: reduxIcon },
        { name: "Type Script", icon: typescriptIcon },
        { name: "Bootstrap", icon: bootstrapIcon },
        { name: "HTML", icon: htmlIcon },
        { name: "CSS", icon: cssIcon },
      ],
    },
    {
      type: "BACKEND",
      language: [
        { name: "NodeJS", icon: nodeIcon },
        { name: "Express.jS", icon: expressIcon },
        { name: "NestJS", icon: nextIcon },
        { name: "socket.io", icon: socketIcon },
        { name: "Laravel", icon: laravelIcon },
      ],
    },
  ];

  const database = {
    type: "DATABASE",
    language: [
      { name: "MongoDB", icon: mongoIcon },
      { name: "PostgreSQL", icon: postgressIcon },
      { name: "MySQL", icon: mysqlIcon },
      { name: "MariaDB", icon: mariadbIcon },
    ],
  };

  const devops = {
    type: "DEVOPS",
    language: [
      { name: "AWS", icon: awsIcon },
      { name: "Google Cloud", icon: cloudIcon },
      { name: "Azure", icon: azureIcon },
      { name: "Jenkins", icon: jenkinsIcon },
      { name: "Docker", icon: dockerIcon },
      { name: "Ansible", icon: ansibleIcon },
      { name: "Kubernetes", icon: KubernetesIcon },
    ],
  };
  return (
    <div
      className="container-fluid css-mdg-1-mp"
      style={{
        backgroundColor: "#F9F9F9",
        textAlign: "center",
      }}
      ref={framworks_Ref}
    >
      <Flip top>
        <div className="our-ser-tlt pt-5 mb-3">
          Focused On Being The Best In JAVASCRIPT Framework
        </div>
        <div
          style={{
            borderBottom: `5px solid ${colors.mainColor}`,
            width: "80px",
            margin: "auto",
          }}
        ></div>
        <div className="ser-desc mt-4 mb-4">Techies In Practice</div>
      </Flip>
      <div className="row px-3">
        {FirstRowfocusOn.map((tech, i) => {
          return (
            <div key={i} className="col-md-4 spacing-40">
              <Zoom>
                <div className="tech-card">
                  <div
                    style={{
                      color: "#002037",
                      fontSize: "22px",
                      fontWeight: "500",
                      textAlign: "start",
                      margin: "10px",
                    }}
                  >
                    {tech.type}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    {tech.language.map((lang, i) => {
                      return (
                        <div key={i} style={{ margin: "5px 6px" }}>
                          <div>
                            <img
                              src={lang.icon}
                              alt="img"
                              //   style={{ width: "20px", height: "20px" }}
                            />
                          </div>
                          <div style={{ fontSize: "16px" }}>{lang.name}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Zoom>
            </div>
          );
        })}
      </div>
      <div className="row mt-5 px-3">
        <Zoom>
          <div className={"col-md-4"}>
            <div className="tech-card">
              <div
                style={{
                  color: "#002037",
                  fontSize: "22px",
                  fontWeight: "500",
                  textAlign: "start",
                  margin: "10px",
                }}
              >
                {database.type}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {database.language.map((lang, i) => {
                  return (
                    <div key={i} style={{ margin: "15px 13px" }}>
                      <div>
                        <img
                          src={lang.icon}
                          alt="img"
                          //   style={{ width: "20px", height: "20px" }}
                        />
                      </div>
                      <div style={{ fontSize: "16px" }}>{lang.name}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Zoom>
        <Zoom>
          <div className={"col-md-8"}>
            <div className="tech-card">
              <div
                style={{
                  color: "#002037",
                  fontSize: "22px",
                  fontWeight: "500",
                  textAlign: "start",
                  margin: "10px",
                }}
              >
                {devops.type}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {devops.language.map((lang, i) => {
                  return (
                    <div key={i} style={{ margin: "10px 45px" }}>
                      <div>
                        <img
                          src={lang.icon}
                          alt="img"
                          //   style={{ width: "20px", height: "20px" }}
                        />
                      </div>
                      <div style={{ fontSize: "16px" }}>{lang.name}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Zoom>
      </div>
    </div>
  );
};

export default FocusedOnBeing;
