import React from "react";
import moment from "moment";
import nodeImg from "../../assets/images/nodeCode.jpeg";
import stateFull from "../../assets/images/statefullStateless.png";
import "./styles.css";
import { colors } from "../../utils/constants";
import Button from "../../components/Button";
import Subscribe from "../../components/Subscribe";

const Blog = () => {
  const blogs = [
    {
      blogType: 1,
      title: "What is Node JS used for?",
      postedOn: new Date(),
      image: nodeImg,
      description:
        "Node.js is a product development innovation that is utilized by the absolute for best parts in worldwide business, to fabricate quick, excellent applications. For",
    },
    {
      blogType: 1,
      title: "Difference between Statefull and Stateless API",
      postedOn: new Date(),
      image: stateFull,
      description:
        "In the dynamic realm of web development and application programming, the choice between stateful and stateless APIs is akin to selecting the right tool",
    },
  ];

  return (
    <div>
      <div
        className="top-home-container get-in-touch-container"
        style={{ minHeight: "350px" }}
      >
        <div className="container-fluid reverse-flip-text">
          <div className="css-mp-blog">
            <div>VIIONN BLOGS</div>
            <div
              style={{
                borderBottom: `3px solid white`,
                width: "100px",
                marginTop: "40px",
              }}
            ></div>
            <div style={{ marginTop: "40px", fontSize: "35px" }}>
              A Blog On the Latest in Technology and Innovation
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="blogs-css-pad">
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                {blogs.map((blog, i) => {
                  return (
                    <div className="col-md-6 mt-3" key={i}>
                      <div className="card">
                        <img
                          className="card-img-top"
                          src={blog.image}
                          alt="Card img cap"
                        />
                        <div className="card-body">
                          <h5 className="card-title">{blog.title}</h5>
                          <h4
                            className="card-text"
                            style={{
                              color: colors.lightBlack,
                              fontSize: "15px",
                            }}
                          >
                            {moment(blog.postedOn).format("MMM DD, YYYY")}
                          </h4>
                          <p
                            className="card-text"
                            style={{
                              color: colors.lightBlack,
                              fontSize: "16px",
                            }}
                          >
                            {blog.description}
                          </p>
                          <Button
                            label={"Show More..."}
                            background="white"
                            color={colors.mainColor}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-md-4 mt-3">
              <div
                className="hire-css-73"
                style={{ background: colors.lightBlack }}
              >
                <div style={{ fontWeight: "700", marginBottom: "10px" }}>
                  Hire Dedicated Remote Developers from Viionn
                </div>
                <div style={{ fontSize: "14px" }}>
                  Onboard{" "}
                  <span style={{ color: colors.mainColor, fontWeight: "500" }}>
                    dedicated remote developers
                  </span>{" "}
                  to your project as quickly as in 2 days. If at any point in
                  time, you feel the developer is not performing as per
                  expectation, you can ask for replacement or end the contract
                  with 0 penalties.
                </div>
              </div>
              <div
                style={{
                  background: "#FFC04F",
                  padding: "13px",
                  borderRadius: "3px",
                  fontSize: "17px",
                  color:'white',
                  marginTop:'25px'
                }}
              >
                Subscribe to our Newsletter
              </div>
              <div className="box-shadow mt-4" style={{ padding: "25px" }}>
                <Subscribe />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
