import React from "react";
import "./styles.css";
import girlImg from "../../assets/test1.png";
import curveImg from "../../assets/images/curve1.png";
import dividerImg from "../../assets/images/verticle-divider.png";
import { colors } from "../../utils/constants";
import Button from "../../components/Button";
import { Fade, Zoom } from "react-reveal";

const TopHome = () => {
  return (
    <div className="top-home-container bg-gradient-main container-fluid">
      <div className="row">
        <Zoom left duration={2000}>
          <div className="col-md-6 left-home-img w3-center">
            <img src={girlImg} alt="img"/>
          </div>
        </Zoom>
        <Fade right duration={2000}>
          <div className="col-md-6 right-home-container">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "end",
              }}
            >
              <h1 className="home-right-top-heading">
                Software <span style={{ color: colors.mainColor }}>Developers</span>{" "}
                <br />
                on Contract in 1 Day
              </h1>
              <img src={curveImg} className="h-curve-img" alt="img"/>
            </div>
            <div
              className="d-flex"
              style={{ width: "100%", padding: "10px 0px" }}
            >
              <div style={{ width: "40%" }}>
                <div className="css-478-th">1 week</div>
                <div className="elementor-21329">Risk-free trial,</div>
                <div className="mt-4">
                  <Button label={"Hire Developers"} width={"auto"} color={colors.mainColor} />
                </div>
              </div>
              <div className="d-flex jcc" style={{ width: "20%" , height:'80%'}}>
                <img src={dividerImg} style={{ marginTop: "20px" }} alt="img"/>
              </div>
              <div style={{ width: "40%" }}>
                <div className="css-478-th">100%</div>
                <div className="elementor-21329">In-House Developers</div>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default TopHome;
