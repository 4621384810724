import React from "react";
import Fade from 'react-reveal/Fade';
import { FaStar } from "react-icons/fa";

import dev1 from "../../assets/images/developers/01.png";
import dev2 from "../../assets/images/developers/02.png";
import dev3 from "../../assets/images/developers/03.png";
import dev4 from "../../assets/images/developers/04.png";
import dev5 from "../../assets/images/developers/05.png";
import dev6 from "../../assets/images/developers/06.png";
import add50 from "../../assets/images/developers/50Add.png";
import add40 from "../../assets/images/developers/40Add.png";
import { colors } from "../../utils/constants";


const Developers = ({developer_Ref}) => {
  const developersArr = [
    {
      type: "React.JS",
      rating: "9.8 / 10 Average Rating",
      images: [dev1, dev2, dev3, add50],
    },
    {
      type: "Node.JS",
      rating: "9.9 / 10 Average Rating",
      images: [dev4, dev5, dev6, add40],
    },
    {
      type: "MERN Stack",
      rating: "9.7 / 10 Average Rating",
      images: [dev4, dev5, dev6, add40],
    },
    {
      type: "Mobile App",
      rating: "9.7 / 10 Average Rating",
      images: [dev1, dev2, dev3, add50],
    },
  ];

  const questions = [
    {
      que: "How Long Does It Take To Hire Developers?",
      ans: "Fastest Onboarding We Have Done Is In 1 Day. Since All Developers Are Employee Of Viionn, We Have Complete Control Over Their Deployment. We Also Maintain A 10% Bench Hence Developers Are Always Available To Join Immediately. ",
    },
    {
      que: "Are The Developers Freelancers ?",
      ans: "We Donot Work With Any Freelancers Or Partner Companies. All The Developers Are 100% Employees Of Viionn. This Allows Us To Have Highest Control On Developer Retention And Happiness. ",
    },
    {
      que: "Can I Hire The Resource Full Time On My Payroll ?",
      ans: "After 1 Year Of The Completion Of The Contract Viionn Allows The Clients To Hire The Resources Full Time On Their Payroll If They Desire It. ",
    },
    {
      que: "How Do We Manage The Developers ?",
      ans: "Developers Will Work Dedicatedly For You. You Can Manage Them As You See Fit. You Can Deploy Your Custom Softwares, Enforce Sharing Restrictions, Give Customized Work Policies, Among Other Things The Way You Do It With Your Full Time Employees.",
    },
    {
      que: "What Are The Payment Terms ?",
      ans: "Similar To Salary You Pay Us On Monthly Basis. Unlike Salary, You Only Have To Pay The Contract Fee And Rest Everything We Manage At Our End. No Hidden Charges, No Surprise Cost, No Surcharge. 100% Transparency And Predictability.",
    },
    {
      que: "What If I Don't Need A Developer Any Longer?",
      ans: "If It Is Planned Then You Can Give Us 1 Month Notice To Offboard A Developer. If It Is Due To Performance Then Our Team Will Supply You With An Immediate Replacement With No Questions Asked. ",
    },
    {
      que: "How Does The 1-Week Risk Free Trial ?",
      ans: "For The First Week Of The Contract, We Allow Our Clients To Test The Developers Over Different Parameters To See If The Developer Is The Right Fit For Them. <br/>If The Client Doesn’t Like The Developer They Can Terminate The Contract Immediately With No Questions Asked.",
    },
  ];
  return (
    <div className="container-fluid sec-developer-container" ref={developer_Ref}>
      <div className="row">
      <Fade left>
        <div className="col-md-6">
          <div>
            <div className="our-ser-tlt">Developers</div>
            <div
              style={{
                borderBottom: `5px solid ${colors.mainColor}`,
                width: "50px",
                marginTop: "20px",
              }}
            ></div>
          </div>
          <div className="row" id='developers'>
            {developersArr.map((developer, i) => {
              return (
                <div className="col-md-6" key={i}>
                  <div className="dev-card mt-4">
                    <div className="companies-heading">
                      <div>{developer.type}</div>
                      <div>Developers</div>
                    </div>
                    <div
                      className="d-flex mt-3"
                      style={{ alignItems: "center" }}
                    >
                      <FaStar
                        style={{
                          color: "green",
                          marginRight: "10    px",
                          fontSize: "24px",
                        }}
                      />
                      <div
                        style={{
                          color: "#434343",
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                      >
                        {developer.rating}
                      </div>
                    </div>
                    <div className="mt-3 pb-4">
                      <div
                        style={{ width: "30%" }}
                        className="bg-info rounded-circle"
                      >
                        <span>
                          <img src={developer.images[0]} alt="img"/>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        </Fade>
        <Fade right>
        <div className="col-md-6">
          <div>
            <div className="our-ser-tlt">Have a Question?</div>
            <div
              style={{
                borderBottom: `5px solid ${colors.mainColor}`,
                width: "50px",
                marginTop: "20px",
              }}
            ></div>
            <div>
              <div className="accordion" id="accordionExample">
                {questions.map((question, i) => {
                  return (
                    <div className="accordion-item" key={i}>
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse${i}`}
                          aria-expanded={i === 0 ? "true" : "false"}
                          aria-controls={`collapse${i}`}
                          style={{color:'#000000', fontSize:'18px', fontWeight:'600', textTransform:'capitalize'}}
                        >
                          {question.que}
                        </button>
                      </h2>
                      <div
                        id={`collapse${i}`}
                        className={`accordion-collapse collapse ${i===0 && 'show'}`}
                        data-bs-parent="#accordionExample"
                        
                      >
                        <div className="accordion-body element-1" style={{textAlign:'start'}}>
                        {question.ans}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        </Fade>
      </div>
    </div>
  );
};

export default Developers;
