import React, { Fragment, useEffect, useRef } from "react";
import { Element } from "react-scroll";
import "./styles.css";
import TopHome from "./TopHome";
import Header from "../../components/Header";
import CompaniesAchieved from "./CompaniesAchieved";
import OurServices from "./OurServices";
import Developers from "./Developers";
import HearFromPeople from "./HearFromPeople";
import FocusedOnBeing from "./FocusedOnBeing";
import AdvantagesForClients from "./AdvantagesForClients";
// import CustomerProtection from "./CustomerProtection";
import ContactUs from "../ContactUs/Index";
import Footer from "../../components/Footer";
import { useLocation } from "react-router-dom";

const Index = () => {
  const developer_Ref = useRef(null);
  const framworks_Ref = useRef(null);

  const pathname = window.location.pathname;
  const location = useLocation();
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (pathname === "/") {
      if (location?.state?.link === "developers") {
        const executeScroll = () => developer_Ref.current.scrollIntoView();
        setTimeout(() => {
          executeScroll();
        }, 1000);
      }
      if (location?.state?.link === "framworks") {
        const executeScroll = () => framworks_Ref.current.scrollIntoView();
        setTimeout(() => {
          executeScroll();
        }, 1000);
      }
    }
  }, [pathname, location?.state?.link]);

  return (
    <Fragment>
      <div id="site-wrapper" className="site-wrapper home-main">
        <Header />
        <div className="content-wrap">
          <TopHome />
          <CompaniesAchieved />
          <OurServices />
          <Element name="developers">
            <Developers developer_Ref={developer_Ref} />
          </Element>
          <HearFromPeople />
          <Element name="framworks">
            <FocusedOnBeing framworks_Ref={framworks_Ref}/>
          </Element>
          <AdvantagesForClients />
          {/* <CustomerProtection /> */}
          <ContactUs hideHeader={true} hideFooter={true} />
        </div>
        <Footer />
      </div>
    </Fragment>
  );
};

export default Index;
