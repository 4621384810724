import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import Button from "./Button";
import { colors } from "../utils/constants";
import startUpImg from "../assets/icons/startup.svg";
import codeImg from "../assets/icons/code.svg";
import loanImg from "../assets/icons/loan.svg";
import teamImg from "../assets/icons/team.svg";
import unicornImg from "../assets/icons/unicorn.svg";
import logoImg from "../assets/logo1.png";
import { useNavigate } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import messageImg from "../assets/icons/message.svg";
import chatbotImg from "../assets/icons/chatbot.svg";
import agri_techImg from "../assets/icons/agri_tech.svg";
import e_comImg from "../assets/icons/e_com.svg";
import logisticsImg from "../assets/icons/logistics.svg";
import marketplaceImg from "../assets/icons/marketplace.svg";
import entertainmentImg from "../assets/icons/entertainment.svg";
import erpImg from "../assets/icons/erp.svg";
import fintechImg from "../assets/icons/fintech.svg";
import project_managementImg from "../assets/icons/project_management.svg";
import healthcareImg from "../assets/icons/healthcare.svg";
import travelImg from "../assets/icons/travel.svg";
import online_educationImg from "../assets/icons/online_education.svg";

const Header = () => {
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  const [dropDownType, setDropDownType] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const handleNavbarToggle = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  useEffect(() => {
    if (isNavbarOpen === "close") {
      document.getElementById("menu-toggle-btn").click();
    }
  }, [isNavbarOpen]);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 0;
      setIsScrolled(scrolled);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const services_right_content = [
    {
      title: "Hire Remote Developers",
      link: "hire-remote_developers",
      description:
        "React, Node, Angular, React Native, PHP, Laravel, .NET, Code Ignitor, WordPress, Python, Django, Android, iOS",
    },
    {
      title: "Product Development",
      link: "product-development",
      description: "Website Development, Mobile App Development, Testing",
    },
    {
      title: "UI/UX",
      link: "ui-ux",
      description:
        "Website Designing, Mobile App Designing, Portal Designing , Clickable prototyping",
    },
    {
      title: "Maintenance",
      link: "maintenance",
      description: "Yearly Maintenance, Automation Testing, DevOps",
    },
  ];

  const industry_right_content = [
    { icon: messageImg, title: "Messaging", link: "/industry-message" },
    { icon: chatbotImg, title: "Chatbots", link: "/industry-chatbot" },
    { icon: agri_techImg, title: "Agri-Tech", link: "/industry-agri_tech" },
    { icon: e_comImg, title: "E-Com", link: "/industry-e_com" },
    { icon: logisticsImg, title: "Logistics", link: "/industry-logistics" },
    {
      icon: marketplaceImg,
      title: "Marketplace",
      link: "/industry-marketplace",
    },
    {
      icon: entertainmentImg,
      title: "Entertainment",
      link: "/industry-entertainment",
    },
    { icon: erpImg, title: "ERP/CRM Software", link: "/industry-erp" },
    { icon: fintechImg, title: "Fin-tech", link: "/industry-fintech" },
    {
      icon: project_managementImg,
      title: "Project Management Tool",
      link: "/industry-project_management",
    },
    { icon: healthcareImg, title: "Healthcare", link: "/industry-healthcare" },
    { icon: travelImg, title: "Travel", link: "/industry-travel" },
    {
      icon: online_educationImg,
      title: "Ed-Tech",
      link: "/industry-online_education",
    },
  ];

  return (
    <div style={{ position: "relative", zIndex: 9 }} className="">
      <nav
        style={{
          transition: "background-color 0.7s ease",
        }}
        className={`navbar navbar-expand-lg d-flex justify-content-between css-mhpadd ${
          isScrolled
            ? "navbar-solid"
            : isNavbarOpen
            ? "navbar-solid"
            : "navbar-transparent"
        }`}
      >
        <button
          id="menu-toggle-btn"
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarTogglerDemo03"
          aria-controls="navbarTogglerDemo03"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={handleNavbarToggle}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="navbar-brand"
          onClick={() => {
            navigate("/");
            window.scroll(0, 0);
          }}
        >
          {/* <img src={isScrolled ? logoBlue : logo} style={{ width: "180px" }} /> */}
          <img
            src={logoImg}
            style={{ width: "75px", transform: "scale(2)", cursor: "pointer" }}
            alt="img"
          />
        </div>

        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarTogglerDemo03"
          style={{ fontSize: "15px" }}
        >
          <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
            <li className="nav-item active">
              <div
                className="nav-link"
                onClick={() => {
                  navigate("/get-in-touch/early-stage-startup");
                  setDropDownType(null);
                  setIsNavbarOpen("close");
                }} // in responsive mode when user tab
                onMouseEnter={() => setDropDownType("SERVICE")}
                onMouseLeave={() => setDropDownType(null)}
              >
                SERVICE
              </div>
            </li>
            <li className="nav-item">
              <div
                className="nav-link"
                onClick={() => {
                  navigate("/get-in-touch/industry-message");
                  setDropDownType(null);
                  setIsNavbarOpen("close");

                }}
                onMouseEnter={() => setDropDownType("INDURSTRY")}
                onMouseLeave={() => setDropDownType(null)}
              >
                INDURSTRY
              </div>
            </li>
            <li className="nav-item">
              <div
                className="nav-link"
                onClick={() => navigate("/blog")}
                onMouseEnter={() => setDropDownType("BLOG")}
                onMouseLeave={() => setDropDownType(null)}
              >
                BLOG
              </div>
            </li>
            <li className="nav-item css8-react-scroll">
              <div
                className="nav-link"
                onMouseEnter={() => setDropDownType(null)}
                onMouseLeave={() => setDropDownType(null)}
              >
                {pathname !== "/" ? (
                  <span
                    onClick={() =>
                      navigate("/", { state: { link: "developers" } })
                    }
                  >
                    DEVELOPERS
                  </span>
                ) : (
                  <Link
                    to="developers"
                    spy={true}
                    smooth={true}
                    offset={-70} // Adjust the offset as needed
                    duration={500}
                  >
                    DEVELOPERS
                  </Link>
                )}
              </div>
            </li>
            <li className="nav-item css8-react-scroll">
              <div
                className="nav-link"
                onMouseEnter={() => setDropDownType(null)}
                onMouseLeave={() => setDropDownType(null)}
              >
                {pathname !== "/" ? (
                  <span
                    onClick={() =>
                      navigate("/", { state: { link: "framworks" } })
                    }
                  >
                    FRAMEWORKS
                  </span>
                ) : (
                  <Link
                    to="framworks"
                    spy={true}
                    smooth={true}
                    offset={-70} // Adjust the offset as needed
                    duration={500}
                  >
                    FRAMEWORKS
                  </Link>
                )}
              </div>
            </li>
            <li
              className="nav-item"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Button
                label={"HIRE TEAM"}
                background={colors.mainColor}
                color="white"
                showArrow={true}
                onClick={() => navigate("/contactUs")}
              />
            </li>
          </ul>
        </div>
      </nav>
      {dropDownType === "SERVICE" ? (
        <div
          style={{
            background: "white",
            position: "fixed",
            top: "82px",
            width: "100%",
            zIndex: "9",
          }}
          onMouseEnter={() => setDropDownType(dropDownType)}
          onMouseLeave={() => setDropDownType(null)}
        >
          <div className="row">
            <div
              className="col-md-8"
              style={{ borderRight: "1px solid lightgray" }}
            >
              <div
                className="d-flex justify-content-end"
                style={{ padding: "35px" }}
              >
                <div
                  className="row"
                  style={{
                    width: "-webkit-fill-available",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <div className="col-md-4">
                    <div className="dd-tlt">BY COMPANY STAGE</div>
                    <div className="mt-4">
                      <div className="d-flex">
                        <div style={{ marginRight: "15px" }}>
                          <img src={startUpImg} alt="img" />
                        </div>
                        <div>
                          <div
                            style={{
                              color: "rgb(13 207 223)",
                              marginBottom: "15px",
                              fontSize: "16px",
                              fontWeight: "400",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              navigate("/get-in-touch/early-stage-startup");
                              setDropDownType(null);
                            }}
                          >
                            Early Stage Startup
                          </div>
                          <div style={{ fontSize: "14px", fontWeight: "400" }}>
                            Get complete support from ideation to the expansion
                            of the startup.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="d-flex">
                        <div style={{ marginRight: "15px" }}>
                          <img src={loanImg} alt="img" />
                        </div>
                        <div>
                          <div
                            style={{
                              color: "rgb(13 207 223)",
                              marginBottom: "15px",
                              fontSize: "16px",
                              fontWeight: "400",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              navigate("/get-in-touch/raised-funding");
                              setDropDownType(null);
                            }}
                          >
                            Raised Funding
                          </div>
                          <div style={{ fontSize: "14px", fontWeight: "400" }}>
                            Speed up your development with domain expert
                            developers.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="d-flex">
                        <div style={{ marginRight: "15px" }}>
                          <img src={unicornImg} alt="img" />
                        </div>
                        <div>
                          <div
                            style={{
                              color: "rgb(13 207 223)",
                              marginBottom: "15px",
                              fontSize: "16px",
                              fontWeight: "400",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              navigate("/get-in-touch/unicorn-startups");
                              setDropDownType(null);
                            }}
                          >
                            Unicorn Startups
                          </div>
                          <div style={{ fontSize: "14px", fontWeight: "400" }}>
                            Setup a remote team of highly productive developers
                            with Viionn.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div
                      style={{
                        borderLeft: "1px solid lightgray",
                        height: "250px",
                        marginTop: "100px",
                      }}
                    ></div>
                  </div>
                  <div className="col-md-4">
                    <div className="dd-tlt">BY ENGAGEMENT MODEL</div>
                    <div className="mt-4">
                      <div className="d-flex">
                        <div style={{ marginRight: "15px" }}>
                          <img src={teamImg} alt="img" />
                        </div>
                        <div>
                          <div
                            style={{
                              color: "rgb(13 207 223)",
                              marginBottom: "15px",
                              fontSize: "16px",
                              fontWeight: "400",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              navigate("/get-in-touch/hire-remote_developers");
                              setDropDownType(null);
                            }}
                          >
                            Hire Remote Developers
                          </div>
                          <div style={{ fontSize: "14px", fontWeight: "400" }}>
                            Expand your team with Top quality Developers within
                            a week
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="d-flex">
                        <div style={{ marginRight: "15px" }}>
                          <img src={codeImg} alt="img" />
                        </div>
                        <div>
                          <div
                            style={{
                              color: "rgb(13 207 223)",
                              marginBottom: "15px",
                              fontSize: "16px",
                              fontWeight: "400",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              navigate(
                                "/get-in-touch/offshore-development-centre"
                              );
                              setDropDownType(null);
                            }}
                          >
                            Offshore Development Centre
                          </div>
                          <div style={{ fontSize: "14px", fontWeight: "400" }}>
                            Take your tech team to scale
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 ">
              <div className="mt-4" style={{ paddingLeft: "20px" }}>
                <div
                  className="mb-5 mt-3"
                  style={{ fontSize: "20px", color: "gray" }}
                >
                  Services
                </div>
                {services_right_content.map((module, i) => {
                  return (
                    <div key={i} className="mt-2">
                      <div
                        style={{
                          color: "#2f2f2f",
                          fontSize: "16px",
                          fontWeight: "400",
                          marginBottom: "20px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          navigate(`/get-in-touch/${module.link}`);
                          setDropDownType(null);
                        }}
                      >
                        {module.title}
                        <FaArrowRight
                          style={{
                            color: "rgb(13 207 223)",
                            marginLeft: "30px",
                          }}
                        />
                      </div>

                      <div
                        style={{
                          color: "#A7A7A7",
                          fontSize: "14px",
                          fontWeight: "400",
                          marginBottom: "20px",
                        }}
                      >
                        {module.description}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ) : dropDownType === "INDURSTRY" ? (
        <div
          style={{
            background: "white",
            position: "fixed",
            top: "82px",
            width: "100%",
            zIndex: "9",
          }}
          onMouseEnter={() => setDropDownType(dropDownType)}
          onMouseLeave={() => setDropDownType(null)}
        >
          <div className="row">
            <div
              className="col-md-5 d-flex jce aic"
              style={{ borderRight: "1px solid lightgray" }}
            >
              <div
                style={{
                  padding: "35px",
                  backgroundColor: "#F9F9F9",
                  width: "255px",
                }}
              >
                <div
                  style={{
                    color: "#000000",
                    fontSize: "18px",
                    fontWeight: "500",
                    marginBottom: "15px",
                  }}
                >
                  Driving Innovation across Different Industry Verticals
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#2F2F2F",
                  }}
                >
                  Viionn intends to help startups to boost their operations and
                  lead market trends across different industry verticals.
                </div>
              </div>
            </div>
            <div className="col-md-7 ">
              <div className="mt-4" style={{ paddingLeft: "20px" }}>
                <div
                  className="mb-5 mt-3"
                  style={{ fontSize: "20px", color: "gray" }}
                >
                  Industries
                </div>
                <div className="row mb-5">
                  {industry_right_content.map((module, i) => {
                    return (
                      <div className="col-md-4 mb-4 cursor-pointer" key={i}>
                        <div
                          className="mt-2 d-flex aic "
                          onClick={() => {
                            navigate(`/get-in-touch${module.link}`);
                            setDropDownType(null);
                          }}
                        >
                          <img src={module.icon} alt="img" />
                          <span
                            style={{
                              color: "#2f2f2f",
                              fontSize: "14px",
                              fontWeight: "500",
                              marginLeft: "20px",
                              cursor: "pointer",
                            }}
                          >
                            {module.title}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Header;
