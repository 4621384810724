import React, { Fragment, useEffect, useState } from "react";
import "./styles.css";
import GetInTouch from "./GetInTouch";
import Header from "../../components/Header";
import { useParams } from "react-router-dom";
import Footer from "../../components/Footer";

const Index = () => {
  const params = useParams();
  const [content, setContent] = useState({}); 

  useEffect(() => {
    window.scroll(0,0)
},[])

  useEffect(() => {
    var data = {};
    var param_type = params.form_type;
    if (param_type === "early-stage-startup") {
      data = {
        form_type: 1,
        heading: "Product Development Services at Viionn",
        description:
          "Building Great Products is possible With a Great Team. Get Product Development Services from Viionn an award winning top web and app development company, developing the futuristic products.",
        subDescription:
          "Building Great Products is possible With a Great Team. Get Product Development Services from Viionn an award winning top web and app development company, developing the futuristic products.",
      };
    } else if (
      param_type === "raised-funding" ||
      param_type === "unicorn-startups" ||
      param_type === "hire-remote_developers"
    ) {
      data = {
        form_type: 1,
        heading: "Hire Remote Developers",
        description:
          "Scale high-performing teams with top-notch industry experts And trained professionals from Viionn, a leading IT staff augmentation company",
        subDescription:
          "Reduce time to market with experienced developers Increasing the efficiency of your team. Hire App Developers Fast and build large products with ease.",
      };
    } else if (param_type === "offshore-development-centre") {
      data = {
        form_type: 1,
        heading: "Offshore Development Center",
        description:
          "Setup your own fully managed remote tech team with Viionn, Offshore Development Center Services. Hire dedicated developers within days and at an affordable cost.",
        subDescription:
          "With complete control, flexible policies, quick onboarding, defined work culture and curated talent pool expedite your product development timelines to become market leaders.",
      };
    } else if (param_type === "product-development") {
      data = {
        form_type: 1,
        heading: "Product Development Services at Viionn",
        description:
          "Building Great Products is possible With a Great Team. Get Product Development Services from Viionn an award winning top web and app development company, developing the futuristic products.",
        subDescription:
          "Re-imagining Product Development for Next Billion, the Passionate team of Viionn is dedicated to deliver future-proof digital experiences.",
      };
    } else if (param_type === "ui-ux") {
      data = {
        form_type: 1,
        heading: "UI/UX Design & Prototyping",
        description:
          "Get UI/UX Design Services From Viionn to Produce Inspiring Digital Experiences for Billions of users.",
        subDescription:
          "Experienced Designers of Viionn, a top web and app development company Have enabled startups and enterprises to deliver brag-worthy designs.",
      };
    } else if (param_type === "maintenance") {
      data = {
        form_type: 2, //form_type is 1 but because we need 'description' in smaller font thats why we write form_type 2
        heading: "Support and Maintenance",
        description:
          "Application support and maintenance at Viionn, a top web and app development company, focuses on the stabilization and optimization of the IT services provided. We don’t let your software turn obsolete and help you stay ahead with timely support and maintenance.",
        subDescription: "",
      };
    } else if (param_type === "industry-message") {
      data = {
        form_type: 2,
        heading: "Hire Messaging App Developers from Viionn",
        description:
          "Leverage the power of socially connected Users spend huge amounts of time in messaging apps. Equip your Software Development Team by Hire Messaging App Developers from Viionn, a top IT Staff Augmentation Company, to create an engaging platforms.",
        subDescription: "",
      };
    } else if (param_type === "industry-chatbot") {
      data = {
        form_type: 2,
        heading: "Hire Chatbots Developers from Viionn",
        description:
          "Build AI-powered chatbots with machine learning Algorithms enhancing engagement with customers. Hire Chatbots Developers/Experts From Viionn, a top web and app development company For crafting customized Chatbots solutions for Your startup.",
        subDescription: "",
      };
    } else if (param_type === "industry-agri_tech") {
      data = {
        form_type: 2,
        heading: "",
        description:
          "Manage Your Agribusiness with amazing Agri-tech software solutions from Viionn, a top software development company that helped agriculture startups to optimize business processes and increase profits up to 10X Offering them to Hire Agri-Tech Software Developers.",
        subDescription: "",
      };
    } else if (param_type === "industry-e_com") {
      data = {
        form_type: 2,
        heading: "Hire eCommerce Developers from India",
        description:
          "Hire eCommerce Developers from India Transform shopping into an experience by Hire eCommerce Developers From Viionn, a top web and app development company that helped innovative startups win Awards, Raised Funds, and Increase ROI up to 10X.",
        subDescription: "",
      };
    } else if (param_type === "industry-logistics") {
      data = {
        form_type: 2,
        heading: "Hire Logistics Software Developers from Viionn",
        description:
          "Organize, coordinate, and simplify complex from the point of origin to point of consumption through Hiring Logistics Software Developers from Viionn, a leading logistics software development company.",
        subDescription: "",
      };
    } else if (param_type === "industry-marketplace") {
      data = {
        form_type: 2,
        heading: "Hire Marketplace Developers from Viionn",
        description:
          "Craft simple and seamless Marketplace to attract Millions of customers and sellers across Worldwide. Viionn, a top web and app development company that offers to Hire Marketplace developers To develop successful Marketplace Solutions with Industry’s Experts in India.",
        subDescription: "",
      };
    } else if (param_type === "industry-entertainment") {
      data = {
        form_type: 2,
        heading: "Hire Entertainment App Developers From Viionn",
        description:
          "Blend mobility with entertainment to stay above the rest with the help of Viionn, A top entertainment mobile app development company that helped startups grow in revenue size up to 10X by offering them to Hire Entertainment App Developers.",
        subDescription: "",
      };
    } else if (param_type === "industry-erp") {
      data = {
        form_type: 2,
        heading: "Hire ERP/CRM Software Developers from Viionn",
        description:
          "Automate your overall business process by Hiring ERP/CRM Software Developers from Viionn- a top ERP/CRM application development company that helped startups make 10X profit.",
        subDescription: "",
      };
    } else if (param_type === "industry-fintech") {
      data = {
        form_type: 2,
        heading: "Hire Fin-tech Developers from Viionn",
        description:
          "Give your business an innovative boost with Fintech software from Viionn, a top mobile app development company trusted by innovative startups and market giants globally.",
        subDescription: "",
      };
    } else if (param_type === "industry-project_management") {
      data = {
        form_type: 2,
        heading: "industry project Management",
        description: "",
        subDescription: "",
      };
    } else if (param_type === "industry-healthcare") {
      data = {
        form_type: 2,
        heading: "Hire Healthcare Developers From Viionn",
        description:
          "Prioritize your healthcare software with Viionn, a leading Web and Mobile App Development Company  that helped startups grow 5X and make their name in the market by offering them to Hire Healthcare Developers.",
        subDescription: "",
      };
    } else if (param_type === "industry-travel") {
      data = {
        form_type: 2,
        heading: "Hire Travel App Developers",
        description:
          "Let your travel business take a flight with an amazing travel app from Viionn, a top mobile application development company that helped startups win awards.",
        subDescription: "",
      };
    } else if (param_type === "industry-online_education") {
      data = {
        form_type: 2,
        heading: "Hire EdTech Software Developers from Viionn",
        description:
          "Get massive engagement through amazing EdTech solutions from Viionn, the top web and app development company that has helped startups grow 5X and carve a brand image in a short span.",
        subDescription: "",
      };
    }

    setContent(data);
  }, [params.form_type]);


  return (
    <Fragment>
      <Header />
      <div className="content-wrap">
        <GetInTouch content={content} />
      </div> 
      <Footer/>     
    </Fragment>
  );
};

export default Index;
