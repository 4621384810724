import React from "react";
import { adminMailId, colors } from "../../utils/constants";
import "./styles.css";
import { FaLinkedinIn } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaTwitterSquare } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { BsSkype } from "react-icons/bs";
import GetInTouchForm from "../../components/GetInTouchForm";

const ContactUs = () => {
  const handleSkypeChatClick = () => {
    const skypeUsername = "live:.cid.47d64632b9b94796"; // vidit.viionn (viionn labs skype user name)

    // Create the skype link
    const skypeLink = `skype:${skypeUsername}?chat`;

    // Open the Skype chatbox
    window.location.href = skypeLink;
  };

  const handleEmailClick = () => {
    var mailtoLink =
      "mailto:" +
      adminMailId +
      "?subject=" +
      encodeURIComponent(`Mail from Viionn website`) +
      "&body=" +
      encodeURIComponent('Message:- ');

    window.location.href = mailtoLink;
  };

  return (
    <div className="top-container">
      <div className="container-fluid" style={{ color: colors.lightBlack }}>
        <div
          className="pt-1 mb-1 text-center"
          style={{ color: colors.mainColor, fontSize: "25px" }}
        >
          Contact us
        </div>
        <div
          style={{
            borderBottom: `3px solid ${colors.lightBlack}`,
            width: "80px",
            margin: "auto",
          }}
        ></div>
        <div className="text-center mt-3" style={{ color: colors.lightBlack }}>
          Looking for vetted developer? Contact with <b>Viionn</b> to hire
          remote developer on contractual basis.
        </div>
        <div className="row">
          <div className="col-md-6">
            <GetInTouchForm />
          </div>
          <div className="col-md-6">
            <div className="cu-m-padding-17">
              <div
                className="cu-right-top-policy-container"
                style={{ background: colors.lightBlack }}
              >
                <div
                  style={{
                    color: colors.mainColor,
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                >
                  No bond Policy.
                </div>
                <div>
                  If the resource doesn’t perform then the contract will be
                  terminated within 1 month of notice.
                </div>
              </div>
              <div
                style={{
                  color: colors.lightBlack,
                  fontSize: "20px",
                  marginTop: "40px",
                }}
              >
                EMAIL
              </div>
              <div
                style={{
                  borderBottom: `2px solid ${colors.mainColor}`,
                  width: "63px",
                }}
              ></div>
              <div
                className="d-flex aic jcsb border-left-main-color fw-500"
                style={{
                  paddingLeft: "15px",
                  marginTop: "15px",
                  cursor: "pointer",
                }}
                onClick={handleEmailClick}
              >
                <div>FOR ANY ENQUIRY</div>
                <div>{adminMailId}</div>
              </div>
              <div
                style={{
                  color: colors.lightBlack,
                  fontSize: "20px",
                  marginTop: "40px",
                }}
              >
                GET CONNECTED
              </div>
              <div
                style={{
                  borderBottom: `2px solid ${colors.mainColor}`,
                  width: "180px",
                }}
              ></div>
              <div className="d-flex flex-wrap aic mt-3">
                <div className="social-icons" onClick={handleSkypeChatClick}>
                  <BsSkype style={{ fontSize: "25px", color: "white" }} />
                </div>
                <div className="social-icons">
                  <FaLinkedinIn style={{ fontSize: "25px", color: "white" }} />
                </div>
                <div className="social-icons">
                  <FaFacebook style={{ fontSize: "25px", color: "white" }} />
                </div>
                <div className="social-icons">
                  <FaInstagramSquare
                    style={{ fontSize: "25px", color: "white" }}
                  />
                </div>
                <div className="social-icons">
                  <FaTwitterSquare
                    style={{ fontSize: "25px", color: "white" }}
                  />
                </div>
                <div className="social-icons">
                  <FaYoutube style={{ fontSize: "25px", color: "white" }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
