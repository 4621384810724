import React from "react";
import Button from "./Button";
import { colors } from "../utils/constants";

const Subscribe = () => {
  return (
    <div>
      <input className="form-control mb-4" placeholder="Your Email Id"/>
      <Button
        label={"SUBSCRIBE"}
        showArrow={true}
        background={colors.lightBlack}
        color="white"
      />
    </div>
  );
};

export default Subscribe;
