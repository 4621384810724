import React from "react";
import { Fade } from "react-reveal";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaQuoteLeft } from "react-icons/fa";
import { colors } from "../../utils/constants";
import "./styles.css";
import usFlag from "../../assets/images/countryFlags/usFlag.png";
import ukFlag from "../../assets/images/countryFlags/ukFlag.png";
import columbiaFlag from "../../assets/images/countryFlags/columbia.png";
import StarRating from "../../components/StarRating";

const HearFromPeople = () => {
  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2500,
    cssEase: "linear",
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768, // Adjust the breakpoint as needed
        settings: {
          slidesToShow: 1, // Change slidesToShow for smaller screens
        },
      },
    ],
  };

  const clientsSay = [
    {
      name: "Saksham Jain",
      says: "Really great developer. Joined our team very quickly and helped to steady the ship. Develops without needed everything explained.",
      clientImg: "https://itinformatix.com/assets/img/Saksham_jain.webp",
      rating: "5",
      clientLocation: "United Kingdom",
      flag: ukFlag,
    },
    {
      name: "Parker Place",
      says: "Talented developer. They proved their expertized in Node JS as well Android app development. Thanks all team members.",
      clientImg: "https://itinformatix.com/assets/img/Parker-Place.webp",
      rating: "4.5",
      clientLocation: "United States",
      flag: usFlag,
    },
    {
      name: "Sergio Gaona",
      says: "OK, he did frontend development with good results. Some difficulties with language because app was in spanish.",
      clientImg: "https://itinformatix.com/assets/img/testimonial-3.jpg",
      rating: "4.5",
      clientLocation: "Columbia",
      flag: columbiaFlag,
    },
    {
      name: "Herman Carneiro",
      says: "Made React Native components and utilities for me quickly and of good quality. Was very impressed, especially for the cost.",
      clientImg: "https://itinformatix.com/assets/img/harman.jpg",
      rating: "4.5",
      clientLocation: "United Arab Emirates",
      flag: columbiaFlag,
    },
    {
      name: "Iman Nekooeimehr",
      says: "Very good experience. Remi was a super star and is an expert in the backend. Very happy with the outcome of the project.",
      clientImg: "https://itinformatix.com/assets/img/user.jpg",
      rating: "5",
      clientLocation: "United States",
      flag: usFlag,
    },
    {
      name: "Marius Rebenciuc",
      says: "Thanks for helping us on our deliverables. Great work. Very happy with the outcome of the project. Well Done!",
      clientImg: "https://itinformatix.com/assets/img/marius.jpg",
      rating: "5",
      clientLocation: "Romania",
      flag: usFlag,
    },
  ];
  return (
    <Fade bottom>
      <div className="container-fluid hfp-container bg-gradient-main">
        <div
          className="our-ser-tlt"
          style={{ color: "white", textAlign: "center" }}
        >
          Hear From The People Who Trust Us
        </div>
        <div style={{ textAlign: "center", marginTop: "30px", width: "100%" }}>
          <div
            style={{
              borderBottom: `5px solid ${colors.mainColor}`,
              width: "50px",
              margin: "auto",
            }}
          ></div>
        </div>
        <div
          style={{
            fontSize: "24px",
            color: "white",
            textAlign: "center",
            fontWeight: "500",
            marginTop: "30px",
          }}
        >
          Testimonials
        </div>
        <div className="row mt-5 sider-conatainer5-mp">
          <Slider {...settings}>
            {clientsSay.map((clientData, i) => {
              return (
                <div key={i} style={{ paddingTop: "25px" }}>
                  <div className="slide-box">
                    <div className="client-city">
                      <img
                        src={clientData.flag}
                        style={{
                          width: "45px",
                          height: "45px",
                        }}
                        alt="img"
                      />
                    </div>
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: colors.mainColor,
                      }}
                    >
                      {clientData.name}
                    </div>
                    <div>
                      <img src={clientData.clientImg} alt="img"/>
                    </div>
                    <div className="mt-2">
                      <StarRating rating={clientData.rating} color={"red"} />
                    </div>

                    <div className="mt-4">
                      <FaQuoteLeft
                        style={{ color: "#ffa50099", fontSize: "22px" }}
                      />{" "}
                      {clientData.says}
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </Fade>
  );
};

export default HearFromPeople;
