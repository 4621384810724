import React from 'react';
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';

const StarRating = ({ rating, totalStars = 5, color }) => {
  const stars = Array.from({ length: totalStars }, (_, index) => {
    const starValue = index + 1;
    const isFullStar = rating >= starValue;
    const isHalfStar = rating >= starValue - 0.5 && rating < starValue;

    return (
      <span key={index} style={{fontSize:'20px'}}>
        {isFullStar ? (
          <FaStar color={color} />
        ) : isHalfStar ? (
          <FaStarHalfAlt color={color} />
        ) : (
          <FaRegStar color={color} />
        )}
      </span>
    );
  });

  return <div>{stars}</div>;
};

export default StarRating;
