import React from "react";
import "./styles.css";
import blueDivider from "../../assets/images/under_line_blue.png";
import { FaCheck } from "react-icons/fa";
import Fade from 'react-reveal/Fade';
import { colors } from "../../utils/constants";



const OurServices = () => {
  const dedicated_developers = [
    "Immediate Onboarding",
    "Quick Replacement",
    "1 Week Paid Trial",
    "1 Month Notice To Release Resource",
    "Review Of Work By Senior Developers",
    "Max. 45 Hours Per Week",
    "Accredit Certificate",
    "Tracking Software",
    "Access To Senior Developer",
    "In House Trainer",
  ];

  const offshore_managed = [
    "Everything In Dedicated Developer",
    "Dedicated HR Manager",
    "Transparent Pricing (Cost Break Down Will Be Shared With You)",
    "Flexible Working Hours",
    "Credit Gifts And Bonus Directly To Resources",
    "Training For Specific Skillset",
    "Requirement Based Hiring From Market",
    "Customize Policies",
    "Define Work Culture",
  ];

  const fixed_cost_project = [
    "Share Requirement Document",
    "Get Quotation",
    "Single Point Of Contact",
    "Milestones Based Reports",
    "Access To Developers",
    "On-The-Go Requirement Changes",
    "Dedicated Developers",
  ];
  return (
    <div
      className="container-fluid"
      style={{
        backgroundColor: "#F9F9F9",
        textAlign: "center",
        paddingBottom: "55px",
      }}
    >
      <Fade bottom>
        <div className="our-ser-tlt pt-5 mb-3">Our Services</div>
        <div
          style={{
            borderBottom: `5px solid ${colors.mainColor}`,
            width: "80px",
            margin: "auto",
          }}
        ></div>
        <div className="ser-desc mt-5 mb-5">
        Viionn Payroll Developers | No Freelancers | No Sub Contracting
        </div>
        <div className="css-mp-78s">  
          <div className="d-flex flex-wrap" style={{ alignItems: "center" }}>
            <div
              className="border-gray-1 w-33-w-100 spacing-40 service-lr-box"
            >
              <div className="heading-title">Dedicated Developers</div>
              <div className="element-1 mt-4">
              Viionn Employee Will Work Dedicated Only For You For The
                Entire Duration
              </div>
              <div className="mt-3">
                <img src={blueDivider} alt="check" />
              </div>
              <div>
                {dedicated_developers.map((string, i) => {
                  return (
                    <div
                      style={{ display: "flex", marginBottom: "15px", textAlign:'start' }}
                      key={i}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginRight: "15px",
                        }}
                      >
                        <FaCheck style={{ color: colors.mainColor }} />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "16px",
                          fontWeight: "400",
                        }}
                      >
                        {string}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div
              className="w-33-w-100 spacing-40"
              style={{
                backgroundImage:
                  `linear-gradient(347deg, ${colors.secondaray} 0.24%, ${colors.mainColor} 97.44%)`,
                padding: "24px 20px 40px 20px",
                color: "white",
                height: "fit-content",
                boxShadow: "0px 0px 10px 6px rgba(0, 0, 0, 0.14)",
                borderRadius: "15px",
              }}
            >
              <div className="heading-title" style={{ color: "white" }}>
                Offshore Managed Team
              </div>
              <div className="element-1 mt-4" style={{ color: "white" }}>
                Companies Can Now Setup Their Complete Tech Team With
                Viionn.
              </div>
              <div className="mt-3 mb-3 white-grad-box">
                <ul style={{ marginBottom: "0" }}>
                  <li className="mb-1">Minimum 10 Member Team</li>
                  <li>Minimum 1 Year Contract</li>
                </ul>
              </div>
              <div>
                {offshore_managed.map((string, i) => {
                  return (
                    <div
                      style={{ display: "flex", marginBottom: "15px" }}
                      key={i}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginRight: "15px",
                          textAlign: "start",
                        }}
                      >
                        <FaCheck style={{ color: colors.secondaray }} />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "16px",
                          fontWeight: "400",
                          textAlign: "start",
                        }}
                      >
                        {string}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div
              className="border-gray-1 w-33-w-100 spacing-40 service-lr-box"
            >
              <div className="heading-title">Fixed Cost Project</div>
              <div className="element-1 mt-4">
                Get Your Idea Converted To A Software At A Fixed Cost
              </div>
              <div className="mt-3">
                <img src={blueDivider} alt="check" />
              </div>
              <div>
                {fixed_cost_project.map((string, i) => {
                  return (
                    <div
                      style={{ display: "flex", marginBottom: "15px" }}
                      key={i}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginRight: "15px",
                        }}
                      >
                        <FaCheck style={{ color: colors.mainColor }} />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "16px",
                          fontWeight: "400",
                        }}
                      >
                        {string}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default OurServices;
