import React from "react";
import blueArrow from "../../assets/icons/sky_blue_arrow.png";
import {Fade} from "react-reveal";
import Button from "../../components/Button";
import { colors } from "../../utils/constants";

const AdvantagesForClients = () => {
  return (
    <div
      className="container-fluid"
      style={{
        textAlign: "center",
      }}
    >
      <div className="our-ser-tlt pt-5 mb-3">Advantages For Clients</div>
      <div
        style={{
          borderBottom: `5px solid ${colors.mainColor}`,
          width: "80px",
          margin: "auto",
        }}
      ></div>
      <div className="ser-desc mt-5 mb-5">Client Focused Implementations</div>
      <div className="d-flex mt-5" style={{ justifyContent: "space-around" }}>
        <div style={{ fontSize: "25px", color: colors.mainColor, fontWeight: "600" }}>
          VIIONN BENEFITS
        </div>
        <div style={{ fontSize: "25px", color: colors.secondaray, fontWeight: "600" }}>
          VALUE FOR CLIENTS
        </div>
      </div>
      <div className="foc-type-cont">
        <Fade left>
          <div className="d-flex jce">
            <div className="sky-blue-container w-0011">
              <div className="content158">High Retentions</div>
            </div>
            <div className="blue-container w-0022">
              <div className="content158">
                Dependable Team for long term partnership
              </div>
              <img
                src={blueArrow}
                style={{ position: "absolute", left: "-10px", top: "12px" }}
                alt="img"
              />
            </div>
          </div>
        </Fade>
        <Fade right>
          <div className="d-flex jce">
            <div className="sky-blue-container w-11011">
              <div className="content158">Fair Pay</div>
            </div>
            <div className="blue-container w-1022">
              <div>
                Satisfied employees tent to stay much longer and help the
                company grow
              </div>
              <img
                src={blueArrow}
                style={{ position: "absolute", left: "-10px", top: "12px" }}
                alt="img"
              />
            </div>
          </div>
        </Fade>

        <Fade left>
          <div className="d-flex jce">
            <div className="sky-blue-container w-2011">
              <div className="content158">100% inhouse Developers</div>
            </div>
            <div className="blue-container w-1022">
              <div>Complete control and transparent process</div>
              <img
                src={blueArrow}
                style={{ position: "absolute", left: "-10px", top: "12px" }}
                alt="img"
              />
            </div>
          </div>
        </Fade>
        <Fade right>
          <div className="d-flex jce">
            <div className="sky-blue-container w-3011">
              <div className="content158">Buddy System</div>
            </div>
            <div className="blue-container w-1022">
              <div>
                Each developer is assigned a buddy who is always available to
                help and guide
              </div>
              <img
                src={blueArrow}
                style={{ position: "absolute", left: "-10px", top: "12px" }}
                alt="img"
              />
            </div>
          </div>
        </Fade>
        <Fade left>
          <div className="d-flex jce">
            <div className="sky-blue-container w-4011">
              <div className="content158">Cost Optimised Development Centre</div>
            </div>
            <div className="blue-container w-1022">
              <div>
                Save Development Cost and Invest more on Growing your business
              </div>
              <img
                src={blueArrow}
                style={{ position: "absolute", left: "-10px", top: "12px" }}
                alt="img"
              />
            </div>
          </div>
        </Fade>
        <Fade right>
          <div className="d-flex jce">
            <div className="sky-blue-container w-5011">
              <div className="content158">Agile</div>
            </div>
            <div className="blue-container w-1022">
              <div>Upscale and Downscale your team with 1 month notice</div>
              <img
                src={blueArrow}
                style={{ position: "absolute", left: "-10px", top: "12px" }}
                alt="img"
              />
            </div>
          </div>
        </Fade>
        <Fade left>
          <div className="d-flex jce">
            <div className="sky-blue-container w-6011">
              <div className="content158">Bench Strength</div>
            </div>
            <div className="blue-container w-1022">
              <div>
                10% Ready Bench for deployment, safe guarding immediate
                requirements
              </div>
              <img
                src={blueArrow}
                style={{ position: "absolute", left: "-10px", top: "12px" }}
                alt="img"
              />
            </div>
          </div>
        </Fade>

        <div className="mt-4 mb-4"> 
          <Button label={'TALK TO AN EXPERT'} width={'auto'} color={colors.mainColor} background="white"/>
        </div>
      </div>
    </div>
  );
};

export default AdvantagesForClients;
