import React, { Fragment, useEffect } from "react";
import Header from "../../components/Header";
import ContactUs from "./ContactUs";
import Footer from "../../components/Footer";

const Index = ({ hideHeader, hideFooter }) => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <Fragment>
      {!hideHeader && <Header />}
      <div className="content-wrap">
        <ContactUs />
      </div>
      {!hideFooter && <Footer />}
    </Fragment>
  );
};

export default Index;
