import React from "react";
import GetInTouchForm from "../../components/GetInTouchForm";

const GetInTouch = ({ content }) => {
  return (
    <div className="top-home-container get-in-touch-container">
      <div className="container-fluid reverse-flip-text">
        <div className="row">
          <div className="col-md-6">
            <div className="git-left-container">
              <div className="content-heading">{content.heading}</div>
              <div
                className={`content-description ${
                  content.form_type === 1 ? "fs-28" : "fs-20"
                }`}
              >
                {content.description}
              </div>
              <div className="content-subDescription">
                {content.subDescription}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <GetInTouchForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
